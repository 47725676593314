import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getGroupedSlotsPerDay, getActiveSlots, getWrittenDate } from '../../services/slotsFormatting';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';

const Container = styled.div`
  margin-bottom: ${({ noMargin }) => noMargin ? 0 : 37}px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: ${({ noMargin }) => noMargin ? 'unset' : '277px'};
    margin-bottom: 0px;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  color: ${colors.navy};
  line-height: 28px;
  font-size: 18px;
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 20px;
    font-size: 14px;
  }
`;

// the duration the shortest slot (30min) in seconds (times 1000 to be consistent with Date object)
const shortestSlotDuration = 30 * 60 * 1000;

// return true if the user select just on slot of 30min
const hasOneSlotSelected = (groupedSlotsPerDay) => {
  const uniqueDaySlots = groupedSlotsPerDay[0].slots;
  return (groupedSlotsPerDay.length === 1) && (uniqueDaySlots.length === 1)
  && (new Date(uniqueDaySlots[0].end) - new Date(uniqueDaySlots[0].begin)) === shortestSlotDuration;
};

class SlotsSummary extends Component {
  render() {
    const { selectedDates, noMargin } = this.props;
    const activeSlots = getActiveSlots(selectedDates);
    const groupedSlotsPerDay = activeSlots.length === 0 ? [] : getGroupedSlotsPerDay(activeSlots);

    if (groupedSlotsPerDay.length === 0) return (<Text />);
    return (
      <Container noMargin={noMargin}>
        {groupedSlotsPerDay.map((oneDaySlots) => {
          const dateLine = oneDaySlots.slots.reduce((accLine, slot, index, slots) => {
            const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(slot, index, slots);
            return rdvEnd ? `${accLine} ${rdvDate} de ${rdvBegin} à ${rdvEnd}` : `${accLine} ${rdvDate} ${rdvBegin}`;
          }, '').replace(/ ,/g, ',');
          return (
            <Text key={oneDaySlots.id}>
              {dateLine}
              {hasOneSlotSelected(groupedSlotsPerDay)
                && (
                  <span>
                    <br />
                    Votre Tilliste se présentera dans cet intervalle.
                  </span>
                )}
            </Text>
          );
        })}
      </Container>
    );
  }
}

SlotsSummary.propTypes = {
  selectedDates: PropTypes.shape({}).isRequired,
  noMargin: PropTypes.bool,
};

SlotsSummary.defaultProps = {
  noMargin: false,
};

export default SlotsSummary;
