import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import moment from 'moment-timezone';

import routesMap from '../../Routes';
import withOrderContext from '../../withOrderContext';
import { pushToLayer } from '../../services/googleTagManager';
import momentFr from '../../config/moment.fr';
import {
  colors,
  mobileThresholdPixels,
  StyledTitle2 as Title2,
} from '../home/v3/styledComponents';
import LogoButton from './LogoButton';
import Calendar from './Calendar';
import SlotSelector from './SlotSelector';
import SlotsSummary from './SlotsSummary';
import calendar from '../../assets/calendar.svg';
import calendarChecked from '../../assets/calendarChecked.svg';
import clock from '../../assets/clock.svg';
import {
  dateFormat,
  getActiveSlots,
  getBeginHour,
  getFirstDaySelectable,
} from '../../services/slotsFormatting';
import Feedback from '../home/v3/Feedback';
import photoLaura from '../../assets/feedbacks/laura.png';
import OrderContext from '../../context/OrderContext';
import getInitialState from '../step1InitialState';

const feedback = {
  photo: photoLaura,
  name: 'Laura',
  jobDescription: 'a fait retoucher ses robes par Ondine',
  // eslint-disable-next-line max-len
  feedback:
    'A l’heure, a bien compris ma commande ! Disponibilité des couturiers et la rapidité et le fait que ce soit à la maison en plus un dimanche enfin parfait quoi !',
  rating: 5,
};

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

function create30MinSlot(hours, is30) {
  return {
    hours,
    minutes: is30 ? 30 : 0,
    string1: is30 ? `${hours}h30` : `${hours}h`,
    hoursEnd: is30 ? hours + 1 : hours,
    minutesEnd: is30 ? 0 : 30,
    string2: is30 ? `${hours + 1}h` : `${hours}h30`,
    isActive: false,
  };
}

function createHourSlot(hours, is30) {
  return {
    hours,
    minutes: is30 ? 30 : 0,
    string1: is30 ? `${hours}h30` : `${hours}h`,
    hoursEnd: is30 ? hours + 1 : hours,
    minutesEnd: is30 ? 30 : 0,
    string2: is30 ? `${hours + 1}h30` : `${hours + 1}h`,
    isActive: false,
  };
}

const createSlot = (isAdviceOrder, hours, is30 = false) => {
  if (isAdviceOrder) return createHourSlot(hours, is30);
  return create30MinSlot(hours, is30);
};

function createAllHourSlot(beginHour, endHour, isAdviceOrder, removeLastOne = true) {
  const slots = [];
  for (let slotHour = beginHour; slotHour <= endHour; slotHour += 1) {
    slots.push(createSlot(isAdviceOrder, slotHour));
    slots.push(createSlot(isAdviceOrder, slotHour, true));
  }
  if (removeLastOne) slots.pop();
  return slots;
}

const P = styled.p`
  font-family: Roboto;
  font-weight: normal;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  color: ${colors.navy};
  margin: 40px 112px 69px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px 0px 40px;
    line-height: 17px;
    font-size: 14px;
    padding: 0px 30px;
  }
`;

const Message = styled.p`
  background: ${colors.navy};
  border-radius: 2px;
  box-shadow: 0px 2px 6px ${colors.shadow};
  color: ${colors.white};
  font-size: 14px;
  line-height: 20px;
  padding: 15px 20px;
  text-align: center;
  width: 427px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    margin: 15px 0 0 0;
    width: 75vw;
  }
`;

const ComponentTitle = styled.p`
  font-family: Libre Baskerville;
  line-height: 28px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: ${(props) => props.noMarginTop ? '0px 0px 15px 8px' : '30px 0px 30px 8px'};
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0 0 5px 8px;
  }
`;

const ComponentsContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  width: 100%;
  margin-bottom: ${(props) => (props.largeMarginBottom ? '60' : '23')}px;
  ${(props) => props.noMarginBottom && 'margin-bottom: 0px;'}
  ${(props) => props.marginTop && 'margin-top: 100px;'}
  ${(props) => props.column && 'flex-direction: column; align-items: flex-start;'}
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: ${(props) => props.mobileReverse ? 'column-reverse' : 'column'};
    align-items: center;
    ${(props) => props.marginTop && 'margin-top: 60px;'}
    ${(props) => !props.noMarginBottom && 'margin-bottom: 20px;'}
  }
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.borderBottom && `border-bottom: 0.75px solid ${colors.lightGrey2}; margin-bottom: 20px;`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 40px;
  }
`;

const ComponentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const getMorningAfternoonAndEveningSlots = (isAdviceOrder, city) => {
  let morningSlots = [];
  let afternoonSlots = [];
  let eveningSlots = [];
  if (isAdviceOrder) {
    if (city === 'Paris') {
      morningSlots = [...createAllHourSlot(7, 8, isAdviceOrder), ...createAllHourSlot(11, 11, isAdviceOrder)];
      afternoonSlots = createAllHourSlot(13, 17, isAdviceOrder);
      eveningSlots = createAllHourSlot(19, 20, isAdviceOrder, false);
    } else {
      morningSlots = [...createAllHourSlot(7, 7, isAdviceOrder), ...createAllHourSlot(10, 11, isAdviceOrder)];
      afternoonSlots = createAllHourSlot(14, 17, isAdviceOrder);
      eveningSlots = createAllHourSlot(19, 20, isAdviceOrder, false);
    }
  } else {
    morningSlots = createAllHourSlot(7, 12, isAdviceOrder, false);
    afternoonSlots = createAllHourSlot(13, 17, isAdviceOrder, false);
    eveningSlots = createAllHourSlot(18, 21, isAdviceOrder);
  }
  return { morningSlots, afternoonSlots, eveningSlots };
};

const getInitSlotBlocks = (isAdviceOrder, city) => {
  const { morningSlots, afternoonSlots, eveningSlots } = getMorningAfternoonAndEveningSlots(isAdviceOrder, city);
  return ([
    {
      isActive: false,
      text: 'Toute la matinée',
      slots: morningSlots.slice(),
    },
    {
      isActive: false,
      text: 'Tout l’après-midi',
      slots: afternoonSlots.slice(),
    },
    {
      isActive: false,
      text: 'Toute la soirée',
      slots: eveningSlots.slice(),
    },
  ]);
};

const getInitDay = (isAdviceOrder) => {
  let initDay = getFirstDaySelectable();
  if (typeof localStorage !== 'undefined' && localStorage.getItem('landingPage') === 'Advice' && isAdviceOrder && [0, 6].includes(initDay.day())) {
    initDay = moment(initDay).add(initDay.day() === 0 ? 1 : 2, 'day');
  }
  return initDay;
};

const Availabilities = ({
  isAdviceOrder, city, preFilledSteps, orderContext: { addOrEditCloth, setPromoCode, initialize },
}) => {
  const { slots, setSlots } = useContext(OrderContext);
  const [date, setDate] = useState(getInitDay(isAdviceOrder));
  const [selectedDates, setSelectedDates] = useState(slots || {});
  const [isSlotClickedTracked, setIsSlotClickedTracked] = useState(false);
  const [allDay, setAllDay] = useState({
    isActive: false,
    text: 'Toute la journée',
  });
  const [slotBlocks, setSlotBlocks] = useState(getInitSlotBlocks(isAdviceOrder, city).slice());

  const trackSlotClick = () => {
    if (!isSlotClickedTracked) {
      setIsSlotClickedTracked(true);
      pushToLayer({
        event: 'Order Funnel - Time Slot Chosen',
      });
    }
  };

  const selectDateAndSlot = () => {
    trackSlotClick();
    const newSelectedDates = { ...selectedDates };
    newSelectedDates[date.format(dateFormat)] = {
      date: moment(date),
      allDay: { ...allDay },
      slotBlocks: [...slotBlocks],
    };
    setAllDay(allDay);
    setSlotBlocks(slotBlocks);
    setSelectedDates({ ...newSelectedDates });
  };

  useEffect(() => {
    if (localStorage.getItem('landingPage') === 'Advice' && !isAdviceOrder) {
      localStorage.removeItem('landingPage');
      localStorage.removeItem('landingPage_expiryKey');
      initialize();
    } else if (isAdviceOrder && preFilledSteps) {
      localStorage.setItem('landingPage', 'Advice');
      localStorage.setItem('landingPage_expiryKey', (new Date()).toISOString());
      initialize();
      setPromoCode('TILLI_ONE_HOUR_ADVICE_29042020');
      addOrEditCloth({
        ...getInitialState(),
        ...(preFilledSteps || {}),
      });
    }
  }, []);

  useEffect(() => {
    selectDateAndSlot();
  }, [slotBlocks]);

  const beginHour = getBeginHour(date);
  const activeSlots = getActiveSlots(selectedDates);

  const isAllActive = (period) => period.reduce((acc, elem) => (acc ? elem.isActive : false), true);

  const toggleSlot = (indexPartDay, indexSlot, isPartDayActive = false) => {
    const newSlotBlocks = [...slotBlocks];
    if (isPartDayActive) {
      newSlotBlocks[indexPartDay].slots[indexSlot].isActive = isPartDayActive;
    } else {
      newSlotBlocks[indexPartDay].slots[indexSlot].isActive = !newSlotBlocks[
        indexPartDay
      ].slots[indexSlot].isActive;
      newSlotBlocks[indexPartDay].isActive = isAllActive(
        newSlotBlocks[indexPartDay].slots,
      );
      allDay.isActive = isAllActive(newSlotBlocks);
    }
    setSlotBlocks(newSlotBlocks);
  };

  const togglePartDay = (indexPartDay, isAllDayActive = false) => {
    const newSlotBlocks = [...slotBlocks];
    if (isAllDayActive) {
      newSlotBlocks[indexPartDay].isActive = isAllDayActive;
      newSlotBlocks[indexPartDay].slots.map((slot, index) => toggleSlot(indexPartDay, index, newSlotBlocks[indexPartDay].isActive));
    } else {
      newSlotBlocks[indexPartDay].isActive = !newSlotBlocks[indexPartDay]
        .isActive;
      newSlotBlocks[indexPartDay].slots.map((slot, index) => toggleSlot(indexPartDay, index, newSlotBlocks[indexPartDay].isActive));
      allDay.isActive = isAllActive(newSlotBlocks);
    }
  };

  const toggleAllDay = () => {
    const newAllDay = { ...allDay };
    const newSlotBlocks = [...slotBlocks];
    newAllDay.isActive = !newAllDay.isActive;
    newSlotBlocks.map((slot, index) => togglePartDay(index, newAllDay.isActive));
    setSlotBlocks(newSlotBlocks);
    setAllDay(newAllDay);
  };

  const updateSlots = (selectedDate) => {
    const newSlotBlocks = selectedDates[selectedDate.format(dateFormat)]
      ? [...selectedDates[selectedDate.format(dateFormat)].slotBlocks]
      : getInitSlotBlocks(isAdviceOrder, city).slice();
    const newAllDay = selectedDates[selectedDate.format(dateFormat)]
      ? {
        ...selectedDates[selectedDate.format(dateFormat)].allDay,
      }
      : {
        isActive: false,
        text: 'Toute la journée',
      };
    setAllDay({ ...newAllDay });
    setSlotBlocks([...newSlotBlocks]);
    setDate(moment(selectedDate));
  };

  const goToOrderSummary = () => {
    setSlots(selectedDates);
    navigate(routesMap.Step1Summary.url);
  };

  const goNext = () => {
    setSlots(selectedDates);
    navigate(routesMap.Step3.url, { state: { isAdviceOrder, city } });
  };

  return (
    <div>
      <Title2>Quand êtes-vous disponible ?</Title2>
      <P>
        Pendant votre RDV à domicile, le couturier prend vos mesures, épingle et
        valide avec vous vos attentes. Il repart ensuite avec le vêtement dans
        son atelier.
      </P>
      <ComponentsContainer noMarginBottom>
        <ComponentContainer borderBottom>
          <ComponentHeader>
            <img src={calendar} alt="Sélectionnez plusieurs dates" />
            <ComponentTitle>Sélectionnez plusieurs dates</ComponentTitle>
          </ComponentHeader>
          <Calendar
            selectedDate={date}
            selectedDates={selectedDates}
            updateSlots={updateSlots}
            isAdviceOrder={isAdviceOrder}
          />
        </ComponentContainer>

        <ComponentContainer>
          <ComponentHeader>
            <img src={clock} alt="Sélectionnez plusieurs créneaux" />
            <ComponentTitle>Et plusieurs créneaux</ComponentTitle>
          </ComponentHeader>
          <SlotSelector
            activeSlots={activeSlots}
            allDay={allDay}
            slotBlocks={slotBlocks}
            beginHour={beginHour}
            toggleAllDay={toggleAllDay}
            togglePartDay={togglePartDay}
            toggleSlot={toggleSlot}
          />
          {activeSlots.length >= 1 && (
            <Message>
              On vous conseille de sélectionner plusieurs créneaux pour garantir
              un rendez-vous dans les meilleurs délais ;)
            </Message>
          )}
        </ComponentContainer>
      </ComponentsContainer>

      <ComponentsContainer justifyContent="flex-start" column largeMarginBottom>
        <ComponentHeader>
          <img src={calendarChecked} alt="Créneaux sélectionnés" />
          <ComponentTitle noMarginTop>
            Créneaux sélectionnés
          </ComponentTitle>
        </ComponentHeader>
        <ComponentContainer>
          {selectedDates && Object.keys(selectedDates).length > 0 && (
            <SlotsSummary
              activeSlots={activeSlots}
              selectedDates={selectedDates}
            />
          )}
        </ComponentContainer>
      </ComponentsContainer>

      <ComponentsContainer mobileReverse>
        <StyledLink onClick={() => !isAdviceOrder && goToOrderSummary()}>
          <LogoButton isAvailable={!isAdviceOrder} back noZoom hideLogo={isAdviceOrder}>
            Modifier la commande
          </LogoButton>
        </StyledLink>

        <StyledLink onClick={() => !!activeSlots.length && goNext()}>
          <LogoButton isAvailable={!!activeSlots.length}>
            Dernière étape, l’adresse
          </LogoButton>
        </StyledLink>
      </ComponentsContainer>

      <ComponentsContainer marginTop noMarginBottom>
        <Feedback isTransparent feedback={feedback} />
      </ComponentsContainer>
    </div>
  );
};

Availabilities.propTypes = {
  orderContext: PropTypes.shape({
    setSlots: PropTypes.func.isRequired,
    slots: PropTypes.shape({}),
    setPromoCode: PropTypes.func.isRequired,
    addOrEditCloth: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
  }).isRequired,
  isAdviceOrder: PropTypes.bool,
  preFilledSteps: PropTypes.shape({}),
  city: PropTypes.string,
};

Availabilities.defaultProps = {
  isAdviceOrder: false,
  preFilledSteps: undefined,
  city: '',
};

export default withOrderContext(Availabilities);
