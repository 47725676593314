const curtainAdjustments = [
  { label: '< 2m de large', id: '9.deco-rid-1' },
  { label: '2m de large ou plus', id: '9.deco-rid-2' },
];

const curtainAlterations = [
  { label: 'Ajuster la taille à mes fenêtres', adjustments: curtainAdjustments },
  { label: 'Réparer un accroc', id: '9.deco-rid-27' },
  { label: 'Faire un raccord', id: '9.deco-rid-4' },
  { label: 'Ajouter un fil de plomb', id: '9.deco-rid-3' },
];

const cushionAlterations = [
  { label: 'Un accroc', id: '9.deco-cou-22' },
  { label: 'Un zip', id: '9.deco-cou-18' },
];

const embroideries = [
  { label: 'Des initiales' },
  { label: 'Un mot (10 lettres)' },
  { label: 'Un dessin' },
  { label: 'En parler en RDV' },
];

const linensProblems = [
  { label: 'Il est abîmé' },
  { label: 'Il est trop grand' },
];

const linensAlterations = [
  {
    label: 'Il y a un accroc',
    id: '9.deco-mai-28',
    question: { label: 'Combien y a-t-il d’accrocs ?', answer: 1 },
  },
  {
    label: 'Il y manque un bouton',
    id: '9.deco-mai-21',
    question: { label: 'Combien manquent-ils de boutons ?', answer: 1 },
  },
  {
    label: 'Un zip est bloqué',
    id: '9.deco-mai-24',
    question: { label: 'Combien de zip sont bloqués ?', answer: 1 },
  },
];

const linensSizes = [
  {
    label: 'Moins d’1m',
    widthAdjustmentId: '9.deco-mai-8',
    heightAdjustmentId: '9.deco-mai-7',
    bothAdjustmentId: '9.deco-mai-9',
  },
  {
    label: 'Moins d’1m60',
    widthAdjustmentId: '9.deco-mai-5',
    heightAdjustmentId: '9.deco-mai-4',
    bothAdjustmentId: '9.deco-mai-6',
  },
  {
    label: 'Plus d’1m60',
    widthAdjustmentId: '9.deco-mai-2',
    heightAdjustmentId: '9.deco-mai-1',
    bothAdjustmentId: '9.deco-mai-3',
  },
];

const linensWhere = [
  { label: 'La largeur', slug: 'widthAdjustment' },
  { label: 'La longueur', slug: 'heightAdjustment' },
  { label: 'Les deux', slug: 'bothAdjustment' },
];

const getList = (items) => items.map((item) => ({ isActive: false, ...item }));

const initialState = {
  selectedCategory: undefined,
  selectedCloth: '',
  selectedPiece: '',
  creationSlug: '',
  creationShape: {},
  selectedCreationNumberOfPieces: 1,
  creationSize: {},
  creationHeight: {},
  creationFirstOption: {},
  creationSecondOption: {},
  selectedProblems: {},
  selectedLining: '',
  numberOfPieces: 1,
  isCurtainLined: undefined,
  activeCurtainAdjustment: -1,
  isCushionSmall: undefined,
  cushionHasZip: undefined,
  isCushionAlterationSelected: false,
  activeEmbroideryFontSize: -1,
  activeEmbroideryDrawingSize: -1,
  isDrawingFull: undefined,
  isDrawingEasy: undefined,
  displayButtons: false,
  selectedFabric: '',
  selectedTypeOfWork: '',
  isFurSelected: false,
  upcyclingCategories: [],
  upcyclingItems: [],
  suitPieces: [],
  suitProblems: [],
  suitItems: [],
  suitPieceLinings: {},
  suitPiecesEmbroideries: {},
  suitPiecesUpcycling: {},
  sofaParts: [],
  sofaPartsSubTypes: {},
  sofaPartsItems: {},
  sofaPartsNumber: {},
};

export default function getInitialState(preSelectedStateValues = {}) {
  return {
    ...initialState,
    ...preSelectedStateValues,
    embroideries: getList(embroideries),
    curtainAlterations: getList(curtainAlterations),
    cushionAlterations: getList(cushionAlterations),
    linensProblems: getList(linensProblems),
    linensAlterations: getList(linensAlterations),
    linensSizes: getList(linensSizes),
    linensWhere: getList(linensWhere),
  };
}

export {
  getList, initialState, curtainAdjustments, curtainAlterations, cushionAlterations,
  embroideries, linensProblems, linensAlterations, linensSizes, linensWhere,
};
